<!-- <div class="row p-3">
  <div class="col">
    <div class="card card-affaire bg-white text-center border-0 ">
     <p style="font-size: larger;">Informations complémentaires </p>
    </div>
  </div>

</div> -->

<div class="row">
  <div class="col-3 p-1 mt-2  ">
    <div class="sidebar-affaire ">
      <div class="card card-affaire bg-white text-center border-0 p-2">
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('infoSpecif')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.InfoComPhysique' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('infoSpecifEn')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{"languages.settings.infocCompMorale" | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('organisme')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text"> {{"languages.settings.infoCompOrganism" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1">
            <button (click)="scroll('compagnie')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoCompCompagnie' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('gamme')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                
                <i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoGamme' | translate}} </span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('instances')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text"> {{"languages.settings.Instance" | translate}}</span>
            </button>
          </div>

        </div>
        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('quality')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.infoBlocQualite' | translate}}</span>
            </button>
          </div>

        </div>

        <div class="mt-3">
          <div class="col py-1 focus-style ">
            <button (click)="scroll('rules')" class="focus-style d-block border-0 full-width text-left">
              <label class="align-details-icon">
                <i class="fa-solid fa-balance-scale opportunities-details-navbar-logo fa-lg"></i>
              </label>
              <span class="sidebar-text">{{'languages.settings.Rules' | translate}}</span>
            </button>
          </div>

        </div>
    
      </div>

    </div>
  </div>


  <div class="col p-1 overflow-auto ">
    <div class="row">
      <div class="col p-0">
        <div class="p-2 mb-2" id="infoSpecif" *ngIf="infoSpecif">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder  row  d-flex justify-content-center align-items-center mt-2 mb-4 card-doc-title" >
              <div class="col-md-6">
<i class="fa-solid fa-user-tie  opportunities-details-navbar-logo fa-lg mr-2"></i>
                {{'languages.settings.InfoComPhysique' | translate}}</div>
             
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
                <button type="button" class="btn-dt-save"  (click)="addConfig('physique')" [disabled]="formErrorphysique" [style.opacity]="formErrorphysique ? '0.5' : '1'">
                  <i class="fa-solid fa-circle-check fa-lg mr-1"></i>
  
                  {{"languages.buttons.enregistrer" | translate}}
                </button>
              </div>
              
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataPhysique" (emitFormBuilder)="getDataPhysiqueForm($event)" (itemerror)="onGetItemErrorPHysique($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="infoSpecifEn" *ngIf="infoSpecifEn">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-warehouse opportunities-details-navbar-logo fa-lg mr-2" ></i>
                {{"languages.settings.infocCompMorale" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container  *ngIf="showLoading" >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('morale')"   [disabled]="formErrormorale" [style.opacity]="formErrormorale ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataMorale" (emitFormBuilder)="getDataMoraleForm($event)"  (itemerror)="onGetItemErrorMorale($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="organisme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompOrganism" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('organisme')" [disabled]="formErrororganisme" [style.opacity]="formErrororganisme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataOrganisme" (emitFormBuilder)="getDataOrganismeForm($event)" (itemerror)="onGetItemErrorOrganisme($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="compagnie" *ngIf="compagnie">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.infoCompCompagnie" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('compagnie')" [disabled]="formErrorCompagnie" [style.opacity]="formErrorCompagnie ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataCompagnie" (emitFormBuilder)="getDataCompagnieForm($event)" (itemerror)="onGetItemErrorCompagnie($event)"></shared-globalProduit-form>
          </div>
        </div>
        <div class="p-2 mb-2" id="organisme" *ngIf="instance">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-layer-group opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{"languages.settings.Instance" | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="submit" class="btn-dt-save" style="float: right;" (click)="addConfig('instance')">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <div class="row justify-content-center ">
              <div class="col-12 ">
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.reposrtServer" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput type="url" [(ngModel)]="bodyInstance.report_server" #report_server="ngModel"
                        pattern="https?://.+">
                      <mat-error *ngIf="report_server.invalid && (report_server.dirty || report_server.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="report_server.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                           {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienSouscription" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.subscription_url" #souscription_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="souscription_url.invalid && (souscription_url.dirty || souscription_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="souscription_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.lienBackoffice" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input type="url" matInput [(ngModel)]="bodyInstance.api_url" #api_url="ngModel"
                        pattern="https?://.+">
                      <mat-error
                        *ngIf="api_url.invalid && (api_url.dirty || api_url.touched)"
                        class="message-erreur">
                        <mat-error *ngIf="api_url.errors.pattern">
<i class="fa-solid fa-triangle-exclamation"></i>
                          {{"languages.role.formLinkInvalid" | translate}}
                        </mat-error>
                      </mat-error>
                    </mat-form-field>

                  </div>
                </div>
                <div class="row">
                  <div class="col-3 mt-4">
                    {{"languages.settings.deviseActuelle" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.currentCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                 {{"languages.settings.formatDevise" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.formatCurrency">
                    </mat-form-field>
                  </div>
                </div>

                <div class="row">
                  <div class="col-3 mt-4">
                     {{"languages.prospect.pays" | translate}} :
                  </div>
                  <div class="col-6">
                    <mat-form-field class="example-full-width w-100">
                      <input matInput [(ngModel)]="bodyInstance.country">
                    </mat-form-field>
                  </div>
                </div>


              </div>
            </div>

          </div>
        </div>
        <div class="p-2 mb-2" id="quality" *ngIf="quality">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-medal opportunities-details-navbar-logo fa-lg"></i>
                 {{'languages.settings.infoBlocQualite' | translate}}
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('qualite')" [disabled]="formErrorQuality" [style.opacity]="formErrorQuality ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataQuality" (emitFormBuilder)="getDataQualityForm($event)" (itemerror)="onGetItemErrorQuality($event)"></shared-globalProduit-form>
          </div>
        </div>

        <div class="p-2 mb-2" id="gamme" *ngIf="gamme">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder row  d-flex justify-content-center align-items-center card-doc-title mt-2 mb-4" >
              <div class="col-md-6"> 
<i class="fa-solid fa-landmark opportunities-details-navbar-logo fa-lg mr-2"></i>
                 {{'languages.settings.infoGamme' | translate}} 
              </div>
              <div class="col-md-6 d-flex justify-content-end">
                <ng-container *ngIf="showLoading"  >
                  <div class="col-3 d-flex justify-content-center align-items-center">
                    <mat-spinner [diameter]="35"></mat-spinner>
                  </div>
              </ng-container> 
              <button type="button" class="btn-dt-save" style="float: right;" (click)="addConfig('gamme')" [disabled]="formErrorGamme" [style.opacity]="formErrorGamme ? '0.5' : '1'">
<i class="fa-solid fa-circle-check fa-lg mr-1"></i>
                {{"languages.buttons.enregistrer" | translate}}
              </button>
              </div>
            </div>
            <shared-globalProduit-form *ngIf="formulaireTrigger" [formBuilderData]="formBuilderDataGamme" (emitFormBuilder)="getDataGammeForm($event)" (itemerror)="onGetItemErrorGamme($event)"></shared-globalProduit-form>
          </div>
        </div>


        <div class="p-2 mb-2" id="rules" *ngIf="rules">
          <div class="card card-affaire bg-white  border-0 p-3 ml-2">
            <div class="card-hearder  row  d-flex justify-content-center align-items-center mt-2 mb-4 card-doc-title" >
              <div class="col-md-5">
<i class="fa-solid fa-balance-scale  opportunities-details-navbar-logo fa-lg mr-2"></i>
                {{'languages.settings.Rules' | translate}}
              </div>
             

        
                  <div class="col-md-7 d-flex align-items-center justify-content-end  pl-5 ">
                    <div *ngIf="isPasswordInputVisible" class="col-11 passwordDiv">
                      <img class=" col-2" src="assets/icons/lock.svg">
                      <div class="col-9 d-flex  justify-content-end pl-0">
                        <mat-form-field class="full-width">
                          <input matInput [type]="showPassword ? 'text' : 'password'"
                            placeholder="{{'languages.affaire.writePassword' | translate}}" [(ngModel)]="password"
                            (change)="validPass()" autocomplete="new-password">
                          <mat-hint>{{'languages.alerts.ableToModify' | translate}}</mat-hint>
                        </mat-form-field>
                        <button type="button" (click)="togglePasswordVisibility()" [disabled]="allow_to_change" class="eyeBtn mb-2">
                          <i class="fa-solid fa-eye-slash fa fa-lg" *ngIf="!showPassword"></i>
                          <i class="fa-solid  fa-eye fa fa-lg" *ngIf="showPassword"></i>
                        </button>
            
                      </div>
                    </div>
                    <div class="col-1">
                      <button type="button" (click)="togglePasswordInput()" [disabled]="allow_to_change" class="btn-lock ">
                        <i class="fa-solid fa-lock-open fa fa-lg" *ngIf="allow_to_change"></i>
                        <i class="fa-solid fa-lock fa fa-lg" *ngIf=" !allow_to_change"></i>
                      </button>
                    </div>
                  </div>
            
            
           


           
              
            </div>
            <div class="row d-flex justify-content-end">
              <button  type="button" class="add-btn-em"  [ngClass]="{'cover': !allow_to_change}" (click)="createNewRule()">
                <img class="fa-icon-add" src="assets/icons/add-icon.svg">&nbsp;
              {{'languages.settings.createNewRule' | translate}}
            </button>

            </div>
        
          <ng-container *ngIf="show_loading">
              <div class="row mb-3 mt-3">
                  <div class="text-center m-auto ">
                      <mat-spinner [diameter]="30"></mat-spinner>
                  </div>
              </div>
          </ng-container>
          <ng-containner class="py-3" *ngIf="!show_loading">
              <div [ngClass]="{'table-scroll': listRules.length > 5 }" id="listRuless">
                  <table class="table align-items-center " id="dataTable1">
                      <thead class="head-table">
                          <tr>
                              <th> {{'languages.alerts.Libelle' | translate}}</th>
                              <th> {{'languages.listing.Type' | translate}}</th>
                              <th> {{'languages.listing.actions' | translate}}</th>
                             
                          </tr>
                      </thead>
                      <tbody class="body-table">
                          <tr *ngFor="let rule of listRules">
                              <td>{{rule?.label}}</td>
                              <td>{{rule?.label_rule_type}}</td>
                              <td>
                                 <a type="button" class="btn-dt-list" [ngClass]="{'cover': !allow_to_change}" (click)=" getInfoRule(rule.id)">
                                  <img src="/assets/icons/edit-icon.svg">
                                </a>
                              </td>
                          </tr>
                      </tbody>
                  </table> 
                  <div *ngIf="listRules.length < 1" class="row">
                      <div class="col-12  d-flex justify-content-center">
                          <img src="assets/icons/nodata.svg">
      
                        </div>
                        <div class="col-12  d-flex justify-content-center">
                          <p class="noData"> {{'languages.affaire.noDataAvailable' | translate}}</p>
                        </div>
                  </div>
              </div>
          </ng-containner>

<div *ngIf="showDetails" class="detailsRules" [ngClass]="{'cover': !allow_to_change}">
  <h2 class="text-center title justify-content-center m-0 w-100" *ngIf="addRule" id="exampleModalLabel">{{'languages.settings.addRule' | translate}}</h2>
  <h2 class="text-center title justify-content-center m-0 w-100" *ngIf="!addRule" id="exampleModalLabel">{{'languages.settings.modifyRule' | translate}}</h2>
  <div class="row"> 
    <div class="col-6">
      <!-- <mat-form-field class="w-100" appearance="standard">
        <mat-label>{{ 'languages.settings.rule' | translate }}</mat-label>
        <input 
          type="text" 
          matInput  
          [formControl]="rule" 
          
          [matAutocomplete]="autoRule" 
          placeholder="{{ 'languages.settings.search' | translate }}" 
          (input)="onRuleInput($event)"  
          name="rule"
        >
        <mat-autocomplete 
          (optionSelected)="getRule($event.option.value)" 
          [displayWith]="displayFnRule" 
          autoActiveFirstOption 
          #autoRule="matAutocomplete"
        >
          <mat-option *ngFor="let option of listRulesFilter | async" [value]="option">
            {{ option.label }}
          </mat-option>
          <mat-option (click)="createNewRule()" > 
            <i class="fa-solid fa-plus"></i> {{ 'languages.settings.createNewRule' | translate }} {{ruleLabel}}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field> -->
      <mat-form-field class="w-100" appearance="standard">
        <mat-label>{{'languages.settings.rule' | translate}} </mat-label>
        <input matInput 
        [(ngModel)]="modifyRule.label"
        type="text" 
        
        
        name="ruleLabel"
         placeholder="{{'languages.settings.rule' | translate}}" 
        >
         
    </mat-form-field>
    </div>
    
    



    <div class="col-6">
    <!-- <mat-form-field class="w-100" appearance="standard">
      <mat-label>{{ 'languages.settings.ruleType' | translate }}</mat-label>
      <input
        type="text"
        matInput
        [formControl]="ruleType"
        [matAutocomplete]="autoRuleType"
        
        placeholder="{{'languages.settings.search' | translate}}"
        name="ruleType"
      />
      <mat-autocomplete
        (optionSelected)="getRuleType($event.option.value)"
        [displayWith]="displayFnRuleType"
        autoActiveFirstOption
        #autoRuleType="matAutocomplete"
      >
        <mat-option *ngFor="let option of listRuleTypesFilter | async" [value]="option">
          {{ option.label }}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field> -->
     <mat-form-field class="w-100" appearance="standard">
        <mat-label>{{'languages.settings.ruleType' | translate}}</mat-label>
        <mat-select [(ngModel)]="modifyRule.rule_type" #matRe required #type="ngModel" placeholder="{{'languages.settings.ruleType' | translate}}" name="type">
            <mat-option *ngFor="let rule of rulesTypes" [value]="rule.id">
                {{rule.label}}
            </mat-option>
        </mat-select>
        <!-- <mat-error *ngIf="type.invalid && (type.dirty || type.touched)" >
            <div *ngIf="type.errors.required">
                <i class="fa-solid fa-triangle-exclamation"></i>
                {{'languages.affaire.typeOblig' | translate}}
            </div>
        </mat-error> -->
    </mat-form-field>
</div>
  </div>

  <div class="row ">
    <ngx-monaco-editor class="monacoeditorparentdiv" [options]="editorOptions" (focus)="onEditorFocus()" 
    (blur)="onEditorBlur()" [(ngModel)]="ruleStr"></ngx-monaco-editor>
  </div>
        


  <div class="row d-flex justify-content-center w-100 mt-2 mb-3">
    <div class="col-6">
        <ng-container *ngIf="show_loading_adding">
            <div class="row mb-3 mt-3">
                <div class="text-center m-auto ">
                    <mat-spinner [diameter]="30"></mat-spinner>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="row text-center justify-content-center w-100">
        
            <button type="button" class="btn-dt-save-popup mr-2" (click)="addConfig('rules')">
<i class="fa-solid fa-circle-check fa-icon-style fa-lg mr-1"></i>
                {{'languages.buttons.ajouter' | translate}}
          </button>
            <button data-dismiss="modal" aria-label="Close"  type="button" class="btn-dt-cancel-popup ml-2" (click)="emptyRule()">
                <i class="fa-solid fa-circle-xmark fa-icon-style fa-lg mr-1"></i>
            {{'languages.buttons.annuler' | translate}}

          </button>
    
           
        
    </div>
    </div>
        
        <div> <button matTooltip="élargir l'éditeur"  (click)="openDialogmonaco()" style="float: right"
          class="btn btn-success d-flex"> <mat-icon>open_in_new</mat-icon> </button></div>

          <ng-template #monacoeditorpopuppre>
            
              <div class="row">
              <div class="col-lg-12" style="width:100%;border-radius: 5px;">
                <div style="width:70vw;height:400px;padding-right:15px;">
                        <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonacobigger()"  matTooltip="Plein écran" ><mat-icon>zoom_out_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>
          
          <!-- Pop up Monaco pretarification -->
          <ng-template #monacoeditorpopupprebigger>
            
              <div class="row">
              <div class="col-lg-12 d-flex justify-content-center">
                <div style="width:95vw;height:85vh;">      <ngx-monaco-editor class="monacoeditor" [options]="editorOptions" [(ngModel)]="ruleStr"></ngx-monaco-editor>
                </div>
              </div>
            </div>
            <div class="row d-flex justify-content-center">
              <div class="col-lg-6 d-flex justify-content-center">
                <button class="modifiertaxebtnzoom" [mat-dialog-close] (click)="openDialogmonaco()"  matTooltip="réduire" ><mat-icon>zoom_in_map</mat-icon></button>
                <button [mat-dialog-close] class="modifiertaxebtnannuler" ><mat-icon>close</mat-icon></button>
                <mat-divider></mat-divider>
          
              </div>
            
            </div>
          </ng-template>
</div>
           
               
            
          </div>
        </div>
     </div>

    </div>
    </div>
  </div>

