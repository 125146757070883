import { TranslateService } from '@ngx-translate/core';
import { NotificationsService } from './../../../shared/NotificationsService/notifications.service';
import { NotificationMessage, NotificationType } from 'src/app/entity/NotificationMessage';
import { ApiDocumentsService } from 'src/app/services/ApiDocuments/api-documents.service';
import { ApiAdminBpmService } from 'src/app/services/ApiAdminBpm/api-admin-bpm.service';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InformationTicket, Status } from 'src/app/entity/TicketsAndSupport/InformationTicket';
import { ApiTicketsService } from 'src/app/services/ApiTickets/api-tickets.service';
import { SharedMenuObserverService } from '../../../services/SharedMenuObserver/shared-menu-observer.service';
 
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
import { Observable, Subscription ,forkJoin, of} from 'rxjs';
import { catchError} from 'rxjs/operators';
import { HistoryServiceTicket } from 'src/app/pages/tickets-and-support/history.service';

import { NotificationsNavbarService } from 'src/app/services/notifications-navbar.service';
import { ApiAuthentificationService } from 'src/app/services/ApiAuthentification/api-authentification.service';
@Component({
  selector: 'app-tickets-details',
  templateUrl: './tickets-details.component.html',
  styleUrls: ['./tickets-details.component.css'],
})
export class TicketsDetailsComponent implements OnInit, OnDestroy {
   
  statusList: Status[] = [];
  loaderStatus: boolean = false;
  infoTicketLoader: boolean = false;

  listunsubscribe: Subscription[] = [];

  isListe: boolean = false;
  @ViewChild('closeModal') closeModal: ElementRef;
  formDataUploadDocument: any[] = [];
  change: boolean;
  changeForm : boolean;
  buttons: any;
  alerts: any;
  appear : boolean = false;
  Enregistrer = 'Mettre à jour';
  isDisabled: boolean;
  DateFermeture;
  traitementDate;
  delai_convent: string = '';
  delai_ferm: string = '';
  date: Date;
  myDate = new Date();
  minDate = new Date();
  loader_delai: boolean = false;
  step: boolean = false;
  languages: any;
  navbarTxt:string="";
  modifiedArray: any[] =[];
  lecture_transaction_financiere_client: any;

  constructor(
    private sharedMenuObserverService: SharedMenuObserverService,
    private apiTicketsServices: ApiTicketsService,
    private notificationsService: NotificationsService,
    private route: ActivatedRoute,
    private apiAdminBpmServices: ApiAdminBpmService,
    private apiDocumentsService: ApiDocumentsService,
    private router: Router,
    private translate : TranslateService,
    private datePipe: DatePipe,
    private NotificationsNavbarService: NotificationsNavbarService,
    private apiAuthentificationService: ApiAuthentificationService,
    private HistoryServiceTicket: HistoryServiceTicket
  ) {
    this.apiDocumentsService.uploadDocument().subscribe((formDataDocuments: FormData) => {
      this.formDataUploadDocument.push(formDataDocuments);
      const lastItemsDict: { [key: string]: any } = {};
      this.modifiedArray=[]
      this.formDataUploadDocument.forEach((item) => {
        const keys = item.Formdata.keys();
      
      for (const key of keys) {
        if (key === 'file[]') {
          lastItemsDict[item.documentId] = item;
        }
      }
        
      });

      this.modifiedArray = Object.values(lastItemsDict);
      
    });
  }

  ngOnDestroy(): void {
    if (!this.isListe) {
      localStorage.removeItem('filtreTicket');
      localStorage.removeItem('pages');
      localStorage.removeItem('sort_field');
      localStorage.removeItem('search');
    }
    this.listunsubscribe.forEach((element) => {
      element?.unsubscribe();
    });
  }



  id: string = '';
  tickets_id: string = '';
  informationTicket = new InformationTicket();
  ngOnInit(): void {
    this.apiTicketsServices.selectedchanges$.subscribe((value) => {
      if (value===true){
        this.change = value;
      }
     
    });

    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {

      this.lecture_transaction_financiere_client = value.lecture_transaction_financiere_client;

    });
    
    this.route.paramMap.subscribe((params) => {
      this.tickets_id = params.get('tickets_id');
    });
    this.translate.get('languages').subscribe((object: any) => {
      this.Enregistrer= object.buttons.MettreAjour
      this.languages = object;
      this.alerts = object.alerts;
      this.buttons = object.buttons;
      this.Enregistrer=object.buttons.MettreAjour
      this.navbarTxt=object.navbar.ticketSupport +' ➜ '+ object.navbar.Détails
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
    });

    this.translate.onLangChange.subscribe(() => {
      this.translate.get('languages').subscribe((object: any) => {
        this.languages = object;
        this.alerts = object.alerts;
        this.buttons = object.buttons;
        this.Enregistrer=object.buttons.MettreAjour
        this.navbarTxt=object.navbar.ticketSupport +' ➜ '+ object.navbar.Détails
        this.sharedMenuObserverService.updateMenu(this.navbarTxt);
      });
  });
    this.getInformationsTicket();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.apiAuthentificationService.listOfAccess$.subscribe((value) => {

      this.lecture_transaction_financiere_client = value.lecture_transaction_financiere_client;

    });
  }

  

  /**
   * Alert en cas de succès
   */
  alertSuccess(data) {
    swal.fire({
      title: this.alerts.Successoperation,
      text: data,
      icon: 'success',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonColor: '#68a45b',
      focusConfirm: true,
    });
  }

  /**
   * Alert en cas d'erreur
   */
  alertError(data) {

    swal.fire({
      title: this.alerts.badHappened,
      text: data,
      icon: 'warning',
      showConfirmButton: true,
      showCloseButton: true,
      confirmButtonText: this.buttons.fermer,
      confirmButtonColor: '#d53a3a',
    });
  }

  /**
   * Alert pour la confirmation les modification sur le ticket
   */
  alertConfirmUpdate(event) {
    swal
      .fire({
        title: this.alerts.confirmUpdateTicket,
        icon: 'info',
        showConfirmButton: true,
        showCloseButton: true,
        showCancelButton: true,
        cancelButtonText:  this.buttons.fermer,
        confirmButtonText: this.buttons.confirmer,
        confirmButtonColor: '#28a8d2',
      })
      .then((result) => {
        if (result.isConfirmed) this.getEmitUpdateTicket(event);
                });
  }

  /**
   * Permets de charger les informations du ticket
   */
  getInformationsTicket(): void {
    this.loader_delai = true;
    this.infoTicketLoader = true;
    const UnsubscribeGetInformationTicketGet = this.apiTicketsServices
      .getInformationTicketGet(this.tickets_id)
      .subscribe(
        (data) => {
          this.loader_delai = false;
          this.informationTicket = data;
          this.infoTicketLoader = false;
          this.informationTicket.date_creation = this.datePipe.transform(data.date_creation, 'yyyy-MM-dd');
          this.informationTicket.date_fermeture = this.datePipe.transform(data.date_fermeture, 'yyyy-MM-dd');
          this.DateFermeture = this.datePipe.transform(data.date_fermeture, 'dd/MM/yyyy');
          this.informationTicket.date_limit = this.datePipe.transform(data.date_limit, 'yyyy-MM-dd');
          if (this.informationTicket.delai_traitement === null) {

            const currentDate = new Date(this.datePipe.transform(this.myDate, 'MM/dd/yyyy'));
            const creactionDate = new Date(this.datePipe.transform(this.informationTicket.date_creation, 'MM/dd/yyyy'));
            const fermetureDate = new Date(
              this.datePipe.transform(this.informationTicket.date_fermeture, 'MM/dd/yyyy')
            );
            if (this.informationTicket.date_fermeture !== null) {
              const Time = fermetureDate.getTime() - creactionDate.getTime();
              const Days = Time / (1000 * 3600 * 24);
              if (Days === 0) {
                this.traitementDate = this.languages?.ticket.closesToday;
              } else {
                this.traitementDate = this.languages?.ticket.closesafter + Math.abs(Days) + this.languages?.ticket.treatmentDays;
              }
            } else {

              const Time = currentDate.getTime() - creactionDate.getTime();
              const Days = Time / (1000 * 3600 * 24);
              if (Days === 0) {
                this.traitementDate = this.languages?.ticket.inTreatmentToday;
              } else {
                this.traitementDate = this.languages?.ticket.inTreatmentSince + Math.abs(Days) + this.languages?.ticket.days;
              }
            }
          } else {
            if (this.informationTicket.date_fermeture === null) {
              this.isDisabled = false;
              const currentDate = new Date(this.datePipe.transform(this.myDate , 'MM/dd/yyyy'));
              const creactionDate = new Date(this.datePipe.transform(this.informationTicket.date_creation , 'MM/dd/yyyy'));
              const value = currentDate.getTime() - creactionDate.getTime();
              const Days_CurrentDate_CreactionDate = value / (1000 * 3600 * 24);
              const Days = this.informationTicket.delai_traitement - Days_CurrentDate_CreactionDate;
              if (Days === 0) {
                this.delai_convent = this.languages?.ticket.lastDays;
              } else if (Days >= 1) {
                this.delai_convent = this.languages?.ticket.reste + Math.abs(Days) +this.languages?.ticket.days;
              } else {
                this.delai_convent = this.languages?.ticket.late + Math.abs(Days) + this.languages?.ticket.days;
              }
            } else {
              this.isDisabled = true;
              const delai_fermture =
                this.informationTicket.delai_traitement -
                (new Date().getDay() - new Date(this.informationTicket.date_creation).getDay());
              if (delai_fermture > 1) {
                this.delai_ferm = Math.abs(delai_fermture) + this.languages?.ticket.befordelay;
              } else {
                this.delai_ferm = Math.abs(delai_fermture) + this.languages?.ticket.afterDelay;
              }
            }
          }
        },
        (error) => {
          if (error) {
            this.loader_delai = false;
            this.infoTicketLoader = false;
            this.alertError(this.alerts.errorChargementTicket);
          }
        }
      );
    this.listunsubscribe.push(UnsubscribeGetInformationTicketGet);
  }

  /**
   * Permets de retourner la liste des statuts
   */
  getStatus(): void {
    this.appearModel = true;
    this.loaderStatus = true;
    const UnsubscribeGetStatusTicket = this.apiTicketsServices.GetStatusTicket().subscribe(
      (data) => {
        this.statusList = data.list_statut;
        this.loaderStatus = false;
      },
      (err) => {
        if (err) {
          this.loaderStatus = false;
          this.alertError(this.alerts.errorChargementListStatut);
        }
      }
    );
    this.listunsubscribe.push(UnsubscribeGetStatusTicket);
  }

  /**
   * Permets de changer la statut du ticket
   */
  appearModel = false;
  changeStatus(libelle, color, id, icone) {
    this.appear = false;
    this.appearModel = false;
    this.step = false;
    this.loaderStatus = true;
    this.informationTicket.statut_details.libelle = libelle;
    this.informationTicket.statut_details.color = color;
    this.informationTicket.statut_details.icon = icone;
    const UnsubscribeUpdateStatusTickets = this.apiTicketsServices
      .updateStatusTickets(this.informationTicket.id_ticket_md5, id)
      .subscribe(
        (data) => {
          this.alertSuccess(data.message);
          this.HistoryServiceTicket.sendFetchDataSignal(true)
          this.apiTicketsServices.pushTicket('updateStatusTicket');
          this.loaderStatus = false;
        },
        (err) => {
          if (err) {
            this.loaderStatus = false;
            this.alertError(this.alerts.errorUpdateStatut);
          }
        }
      );
    this.listunsubscribe.push(UnsubscribeUpdateStatusTickets);
  }

  /**
   * Permets de naviguer vers les parties mentionnez
   */
  scroll(el: string) {
    const element = document.getElementById(el);
    const offset = 120;
    const bodyRect = document.body.getBoundingClientRect().top;
    const elementRect = element.getBoundingClientRect().top;
    const elementPosition = elementRect - bodyRect;
    const offsetPosition = elementPosition - offset;

    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  }

  /**
   * Permets de détecter la modification sur le ticket
   */
  updateTicket(): void {
    this.apiTicketsServices.pushTicket('updateTicket');
  }

  checkInformationSpecifiqueTicket(): void {
    this.apiTicketsServices.pushTicket('formTicket');
  }

  /**
   * Permets de modifier les informations specifique
   */
  geEmitInfoSpecifique(value): void {
    this.informationTicket.form = { data: value };
  }

  // changeInInfoTicket(value){
  //   this.change=value
  // }

  /**
   * Permets de modifier le ticket
   */
  loader: boolean = false;
  getEmitUpdateTicket(event): void {
        const subscriptionsToFork: any[] = [];
    if (this.modifiedArray.length > 0) {
      this.loader = true;
      

      this.modifiedArray.forEach((element, index) => {
        subscriptionsToFork.push(
          this.apiTicketsServices.uploadDocumentTicket(element.Formdata).pipe(
            catchError((error) => {
              return error; // Return an empty observable to allow zip to continue with the next observable
            })
          )
        );
       
      });
      forkJoin(subscriptionsToFork).subscribe(
        (results) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.success;
          notificationMessage.title = this.alerts.Successoperation;
          notificationMessage.message = this.alerts.fileDownloadedSuccess;
          this.notificationsService.sendMessage(notificationMessage);
          this.apiDocumentsService.loadDocument$.next('refreshTicket');
          this.HistoryServiceTicket.sendFetchDataSignal(true)
          this.formDataUploadDocument=[]
          this.modifiedArray=[]
          this.loader = false;
           
          
        },
        (error) => {
          const notificationMessage = new NotificationMessage();
          notificationMessage.type = NotificationType.error;
          notificationMessage.title = this.alerts.Operationechoue;
          notificationMessage.message = error.message;
          this.notificationsService.sendMessage(notificationMessage);
          this.loader = false;
  
        
        }
      );
      
   
    }

    this.checkInformationSpecifiqueTicket();
    if (this.apiAdminBpmServices.sharedFormIsValid) {
  if (this.change) {
        this.loader = true;
        const UnsubscribeUpdateTickets = this.apiTicketsServices.updateTickets(event).subscribe(
          (data) => {
            this.alertSuccess(data.message);
            this.HistoryServiceTicket.sendFetchDataSignal(true)
            this.apiTicketsServices.pushTicket('');
            this.NotificationsNavbarService.triggerNotificationUpdate();
            this.infoTicketLoader = false;
            this.loader = false;
            // this.getInformationsTicket();
            this.change = false;
            this.informationTicket.comm_ticket = '';
          },
          (err) => {
            this.alertError(err.message);
            this.infoTicketLoader = false;
            this.loader = false;
          }
        );
        this.listunsubscribe.push(UnsubscribeUpdateTickets);
     }
      if (this.changeForm) {
        this.loader = true;
        const UnsubscribeUpdateTicketsinformationSpecifiques = this.apiTicketsServices
          .updateTicketsinformationSpecifiques(this.informationTicket.form, this.tickets_id)
          .subscribe(
            (data) => {
              this.loader = false;
              this.alertSuccess(data.message);
              this.HistoryServiceTicket.sendFetchDataSignal(true)
              this.infoTicketLoader = false;
              this.loader = false;

              this.changeForm = false;
            },
            (err) => {
              this.infoTicketLoader = false;
              this.loader = false;
              this.alertError(err.message);
            }
          );
        this.listunsubscribe.push(UnsubscribeUpdateTicketsinformationSpecifiques);
      }
    }
  }

  /**
   * Permets de retourner à la liste
   */
  returnToList(): void {
    this.isListe = true;
    const savedFilter=localStorage.getItem('savedFilter');
    const savedCollection=localStorage.getItem('savedCollection');
    const queryParams = {
      filter: savedFilter,
      collection: savedCollection,
    };
    this.router.navigate(['ticketsandsupport'], { queryParams: queryParams });
  }

  NaviagateToAffaireDetials(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/affaires/details/' + id]));
    window.open(url, '_blank');
    //  this.router.navigate(['/affaires/details/' + id]);
  }

  NaviagateToTicketDetails(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/ticketsandsupport/details/' + id]));
    window.open(url, '_blank');

  }


  NaviagateToOPpDetials(id: string) {
    const url = this.router.serializeUrl(this.router.createUrlTree(['/opportunities/details/' + id + '/prospect']));
    window.open(url, '_blank');
    // this.router.navigate(['/opportunities/details/' + id+"prospect"]);
  }

  uploadDocumentTicket(formData: FormData): void {
    this.apiTicketsServices.uploadDocumentTicket(formData).subscribe(
      (response: any) => {
        this.loader = false;
      },
      (error) => {
        this.loader = false;
        const notificationMessage = new NotificationMessage();
        notificationMessage.type = NotificationType.error;
        notificationMessage.title = this.alerts.Operationechoue
        notificationMessage.message = error.message;
        this.notificationsService.sendMessage(notificationMessage);
      }
    );
  }

  getChangeForm(event) {
    this.changeForm = event;
  }

  appearListStatut() {
    this.appear = true;
    this.getStatus();
  }

  close() {
    this.step = false;
    this.appear = false;
    this.appearModel = false;
  }

  openModalUpdate() {
    this.appear = true;
    this.step = true;
    this.appearModel = true;
    this.getStatus();
  }

  resetStatus() {
    this.step = false;
    this.appear = false;
    this.appearModel = false;
  }
}
